li a {
	display: block;
    border-left: 4px solid transparent;
}
li.active > a {
  border-color: #ff3885;
  background-color: #FFFFFF;
}
li.active {
    background-color: #FFFFFF;
}
.logo {
  display: block;
  margin: 0 auto;
  height: 39px;
}
li.listLogo {
  background-color: #FFFFFF;
  padding: 12px;
}