body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f9f9f9;
}

#root {
  height: '100%';
}

a:link {
  text-decoration: inherit;
  color: inherit;
}

a:visited {
  text-decoration: inherit;
  color: inherit;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus{
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

li a {
	display: block;
    border-left: 4px solid transparent;
}
li.active > a {
  border-color: #ff3885;
  background-color: #FFFFFF;
}
li.active {
    background-color: #FFFFFF;
}
.logo {
  display: block;
  margin: 0 auto;
  height: 39px;
}
li.listLogo {
  background-color: #FFFFFF;
  padding: 12px;
}
